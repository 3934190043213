import React, { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import {
  enterAnimation,
  enterDelayedAnimation,
  exitAnimation
} from "./animation"
import { useComponents } from "../../components"
import {
  CardElement,
  Elements as StripeElements,
  useElements // alias as useStripeElements ??
} from "@stripe/react-stripe-js"
import usePayment from "./use-payment"

import {
  formatCheckout,
  formatInstallmentsDuration,
  formatInstallmentsPrice
} from "../../utils/price-functions"

import SelectInput from "../../render/elements/select-input"
import useTaxConfig from "./use-tax-config"
import useCountries from "./use-countries"
import EmailValidation from "./email-validation"

function Checkout({
  styles,
  price,
  coupon,
  context,
  quantity,
  onPurchase,
  onBack,
  onCouponFailure,
  isVisible,
  onAnimationDone
}) {
  const [processing, payment, errors, stripe, buy] = usePayment(context)
  const Elements = useComponents()

  const [emailValidation, setEmailValidation] = useState({})

  useEffect(() => {
    if (!processing && payment) {
      onPurchase(payment)
    }
  }, [processing, payment])

  useEffect(() => {
    if (errors && errors.keys && errors.keys.includes("coupon")) {
      onCouponFailure(errors)
    }

    if (errors && errors.email_validation) {
      setEmailValidation(errors.email_validation)
    } else {
      setEmailValidation({})
    }
  }, [errors])

  const quantityLabel = quantity > 1 ? `${quantity}x` : ""
  const totalLabel = quantity > 1 ? "Total:" : ""

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          key="checkout-card-inner"
          className={`formkit-card-inner--checkout`}
          animate={enterAnimation}
          exit={exitAnimation}
        >
          <motion.div
            key="main-content-checkout"
            className="formkit-main"
            initial={{ opacity: 0 }}
            animate={enterDelayedAnimation}
          >
            <header>
              <h1 className="formkit-heading-primary font-display">
                <button
                  className="formkit-back-button"
                  style={{
                    backgroundColor: "transparent",
                    verticalAlign: "middle",
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                  title="Back"
                  onClick={onBack}
                >
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                  >
                    <path d="M3.97 11.467a.75.75 0 0 0 0 1.064l6.016 5.977a.75.75 0 0 0 1.057-1.064l-4.735-4.705H19.5a.75.75 0 0 0 0-1.5H6.328l4.715-4.685A.75.75 0 0 0 9.986 5.49L3.97 11.467z"></path>
                  </svg>
                </button>
                Order Summary
              </h1>
              <div className="formkit-product-summary">
                <div className="formkit-image">
                  <Elements.Image
                    className="formkit-image"
                    name="image"
                    size={{ h: 400, w: 200 }}
                    defaults={{
                      src:
                        "https://cdn.convertkit.com/assets/images/commerce/product-placeholder-square-small.png"
                    }}
                  />
                </div>
                <div className="formkit-product-summary__details">
                  <h2 className="formkit-product-summary__title">
                    {quantityLabel} {context.name}
                  </h2>
                  <p className="formkit-product-summary__price">
                    {price.installments && (
                      <>
                        {formatInstallmentsDuration(price, coupon)} <br />
                        <span style={{ fontSize: "0.9rem", opacity: "0.6" }}>
                          {formatInstallmentsPrice(price, coupon)}
                        </span>
                      </>
                    )}

                    {!price.installments && (
                      <>
                        {totalLabel} {formatCheckout(price, coupon, quantity)}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </header>
            <div className="formkit-divider" />
            <div role="main">
              {errors && <PaymentError message={errors.messages} />}
              <StripeElements stripe={stripe}>
                <CheckoutForm
                  stripe={stripe}
                  onSubmit={buy}
                  price={price}
                  coupon={coupon}
                  quantity={quantity}
                  processing={processing}
                  context={context}
                  emailValidation={emailValidation}
                  labelStyles={styles.forElement("purchase_label")}
                  inputStyles={styles.forElement("purchase_input")}
                />
              </StripeElements>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const CheckoutForm = ({
  stripe,
  onSubmit,
  price,
  coupon,
  quantity,
  processing,
  labelStyles,
  inputStyles,
  context,
  emailValidation
}) => {
  const [state, setState] = useState({
    email_address: "",
    name: "",
    address_country: "",
    price: price
  })

  const [emailInvalid, setEmailInvalid] = useState(
    emailValidation.email_invalid
  )
  const [emailDidYouMean, setEmailDidYouMean] = useState(
    emailValidation.did_you_mean
  )
  const [skipEmailValidation, setSkipEmailValidation] = useState(false)

  const taxConfig = useTaxConfig(context)
  const taxCollectionEnabled = taxConfig && taxConfig.collection_enabled
  const { countries } = useCountries(context, taxCollectionEnabled)

  const textOpacity = processing ? 0 : 1
  const svgOpacity = processing ? 1 : 0

  const elements = useElements()

  useEffect(() => {
    setEmailInvalid(emailValidation.email_invalid)
    setEmailDidYouMean(emailValidation.did_you_mean)
  }, [emailValidation])

  const handleChange = (event) => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })

    if (name == "email_address") {
      setEmailInvalid(false)
      setEmailDidYouMean(null)
      setSkipEmailValidation(false)
    }
  }

  const handleSuggestionAccepted = () => {
    setState({ ...state, email_address: emailDidYouMean })
    setEmailDidYouMean(null)
    setEmailInvalid(false)
    setSkipEmailValidation(true)
  }

  const handleSuggestionIgnored = () => {
    setEmailDidYouMean(null)
    setEmailInvalid(false)
    setSkipEmailValidation(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    onSubmit({
      card: elements.getElement(CardElement),
      coupon: coupon,
      quantity: quantity,
      skip_email_validation: skipEmailValidation,
      ...state
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="formkit-heading-primary ">Pay with card</div>
      <div className="formkit-field">
        <label htmlFor="email_address" style={labelStyles}>
          Email
        </label>
        <input
          className="input"
          id="email_address"
          type="email"
          name="email_address"
          pattern="^.+@.+\..+$"
          required
          value={state.email_address}
          onChange={handleChange}
        />
        {!emailInvalid && (
          <span className="formkit-input-help">
            You’ll receive receipts and notifications at this email address.
          </span>
        )}
        {emailInvalid && (
          <EmailValidation
            emailInvalid={emailInvalid}
            emailSuggestion={emailDidYouMean}
            onSuggestionAccepted={handleSuggestionAccepted}
            onSuggestionIgnored={handleSuggestionIgnored}
          />
        )}
      </div>

      {taxCollectionEnabled && countries && (
        <SelectInput
          autoComplete="address-level1"
          required={true}
          data-element="country-select"
          label="Location"
          placeholder="Select your country"
          labelStyles={labelStyles}
          name="address_country"
          value={state.address_country}
          onChange={handleChange}
          options={countries}
        />
      )}

      <div className="formkit-field">
        <label htmlFor="name" style={labelStyles}>
          Name on card
        </label>
        <input
          id="name"
          name="name"
          className="input"
          type="text"
          placeholder="Jane Smith"
          autoComplete="name"
          required
          value={state.name}
          onChange={handleChange}
        />
      </div>
      <div className="formkit-field">
        <label htmlFor="card" style={labelStyles}>
          Card
        </label>
        <CardElement
          id="card"
          options={{
            style: {
              base: {
                iconColor: "#424770",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                color: inputStyles.color,
                ":-webkit-autofill": {
                  color: inputStyles.color
                },
                "::placeholder": {
                  color: `${inputStyles.color}90`
                }
              },
              invalid: {
                color: "#9e2146",
                iconColor: "#9e2146"
              }
            }
          }}
        />
      </div>
      <div className="formkit-divider" />
      <motion.button
        className="formkit-purchase-button"
        type="submit"
        disabled={!stripe || processing}
        whileHover={{ scale: 1.025 }}
        whileTap={{ scale: 0.95 }}
      >
        <motion.span
          key="purchase-button-text"
          animate={{ opacity: textOpacity }}
        >
          Pay {formatCheckout(price, coupon, quantity)}
        </motion.span>
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
          initial={{ opacity: 0, transform: "scale(0)" }}
          animate={{
            opacity: svgOpacity,
            transform: `scale(${svgOpacity})`
          }}
        >
          <path d="M25.251 6.461c-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615V6.461z">
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            />
          </path>
        </motion.svg>
      </motion.button>

      {taxCollectionEnabled && taxConfig.vat.enabled && (
        <span
          className="formkit-input-help"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          All prices are inclusive of VAT
        </span>
      )}
    </form>
  )
}

const PaymentError = ({ message }) => {
  return (
    <motion.div
      key="payment-error-message"
      className="error"
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: 1,
        height: "auto",
        transition: {
          type: "spring",
          stiffness: 200,
          damping: 30,
          opacity: { delay: 0.1 }
        }
      }}
      exit={{
        opacity: 0,
        height: 0,
        transition: {
          type: "spring",
          stiffness: 300,
          damping: 35,
          height: { delay: 0.3 }
        }
      }}
    >
      {message}
    </motion.div>
  )
}

export default Checkout
